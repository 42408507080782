import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/main';

const NotFoundPage = () => (
	<div>
		<Helmet>
			<link rel="stylesheet" href="https://kinetic-styles.netlify.app/Styles.css" />
		</Helmet>
		<Layout title="404: Not found">
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Layout>
	</div>
);

export default NotFoundPage;
